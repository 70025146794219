// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostResolveStartRegionRequest {
  region: 'eu' | 'us';
  /** The user's email, organization name, or workspace URL that the user entered */
  query: string;
}

export function postResolveStartRegionRequest(
  requestParams: PostResolveStartRegionRequest,
): Request {
  const data = {
    query: toSnakeKeys(requestParams.query),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/saml/resolve-start-region`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
