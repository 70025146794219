import {
  type FormHelpers,
  Form,
  TextInput,
  Button as SubmitButton,
} from '@mentimeter/ragnar-form';
import { Meta } from '@mentimeter/ragnar-ui';
import type { ResponseError } from './types';
import SAMLErrorHandler from './SAMLErrorHandler';

interface SAMLFormProps {
  query: string | undefined;
  onSubmit: ({ query }: { query: string }, formikActions: FormHelpers) => void;
  isLoading: boolean;
  error: ResponseError | undefined;
}

export default function SAMLForm({
  query = '',
  onSubmit,
  isLoading,
  error,
}: SAMLFormProps) {
  return (
    <Form
      initialValues={{
        query,
      }}
      validationSchema={null}
      onSubmit={onSubmit}
      width={1}
      alignItems="center"
      justifyContent="center"
    >
      <TextInput
        width={1}
        mb={2}
        name="query"
        type="text"
        label="Email or workspace name"
        autoFocus={true}
        hintText={
          <Meta py={1} fontSize={1} lineHeight="125" display="inline-block">
            Enter your work email or the name of your organization’s workspace
            to sign in.
          </Meta>
        }
      />
      {!!error && <SAMLErrorHandler error={error} />}
      <SubmitButton
        variant="primary"
        type="submit"
        state={isLoading ? 'loading' : undefined}
        disabled={isLoading}
        width={1}
        mt={3}
      >
        Continue
      </SubmitButton>
    </Form>
  );
}
