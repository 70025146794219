'use client';

import { useCallback, useState } from 'react';
import {
  postResolveStartRegionRequest,
  postResolveStartRegionResponse,
} from '@core-api/saml/saml/resolve-start-region';
import {
  postStartRequest,
  postStartResponse,
  type PostStartRequest,
} from '@core-api/saml/saml/start';
import { useRouter } from '@mentimeter/next-navigation';
import { type FormHelpers } from '@mentimeter/ragnar-form';
import type { ResponseError, Regions } from './types';
import SAMLForm from './SAMLForm';

interface StartResult {
  redirect: string;
  error: null;
}

interface StartErrorResult {
  redirect: null;
  error: ResponseError;
}

async function resolveRegion(query: string): Promise<Regions> {
  const response = await fetch(
    postResolveStartRegionRequest({
      region: 'eu',
      query,
    }),
  );

  const data = await postResolveStartRegionResponse(response);
  return data.region;
}

async function createSAMLAuthRequest({
  query,
  continuePath,
}: {
  query: string;
  continuePath: string | undefined;
}): Promise<StartResult | StartErrorResult> {
  try {
    const region = await resolveRegion(query);

    const requestData: PostStartRequest = { query, region };
    if (continuePath) {
      requestData.continue = continuePath;
    }

    const response = await fetch(postStartRequest(requestData));

    if (response.ok) {
      const data = await postStartResponse(response);
      return { redirect: data.redirect, error: null };
    }

    const error = (await response.json()) as ResponseError;
    const requestId = response.headers.get('request-id') ?? undefined;

    return { redirect: null, error: { ...error, requestId } };
  } catch (error) {
    return { redirect: null, error: { code: 'unknown' } };
  }
}

export interface ErrorData {
  code: string;
  requestId: string | undefined;
}

export default function SAMLStartHandler({
  initialQuery,
  continuePath,
  initialError,
}: {
  initialQuery: string | undefined;
  continuePath: string | undefined;
  initialError?: ResponseError | undefined;
}) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ResponseError | undefined>(initialError);

  const handleSubmit = useCallback(
    async ({ query }: { query: string }, formikActions: FormHelpers) => {
      setError(undefined);

      if (!query.trim()) {
        formikActions.setErrors({
          query: 'Please enter your work email or workspace name.',
        });
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      const result = await createSAMLAuthRequest({ query, continuePath });

      if (result.redirect) {
        router.push(result.redirect);
        return;
      }

      const errorResult = result as StartErrorResult;
      setError(errorResult.error);
      setIsLoading(false);
    },
    [continuePath, router],
  );

  return (
    <SAMLForm
      query={initialQuery}
      isLoading={isLoading}
      error={error}
      onSubmit={handleSubmit}
    />
  );
}
