// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface PostResolveStartRegionResponse {
  /** The region associated with the user's email address in the SAML response */
  region: 'eu' | 'us';
}

export async function postResolveStartRegionResponse(
  response: Response,
): Promise<PostResolveStartRegionResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostResolveStartRegionResponse;
}
