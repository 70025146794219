import { SSOFormTermsAndPolicies } from '@mentimeter/sso-ui';
import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { Box, Button, Heading, Clickable } from '@mentimeter/ragnar-ui';
import SSOIcon from './SSOIcon';

const PageWrapper = ({ children }: { children: React.ReactElement }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      flex="1 1 auto"
      height="100vh"
      py={4}
      px={3}
      bg="#F4F4F6"
    >
      <Clickable
        href="/"
        aria-label="To start page"
        width="100%"
        mb={4}
        alignItems="center"
      >
        <MentimeterLogoType height={25} />
      </Clickable>
      <Box
        width={['90%', '410px']}
        bg="bg"
        borderRadius={2}
        alignItems="center"
        p={4}
      >
        <SSOIcon />
        <Heading textAlign="center" my={4} fontSize={5}>
          Single Sign On
        </Heading>
        {children}
        <SSOFormTermsAndPolicies />
        <Button variant="subtle" href="/auth/login">
          Login without SSO
        </Button>
      </Box>
    </Box>
  );
};
export default PageWrapper;
